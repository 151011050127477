import React from "react";
import { getSettingsPageSubscriptionTab } from "../../utility/SubscriptionProvider";
import { getSalesEmailAddress } from "../../utility/LocalizedEmailAddresses";

export const ACTIVATE_PLAN = "Activate Plan";
export const BILLING_TAB_DESTINATION = `/settings?defaultTab=${getSettingsPageSubscriptionTab()}`;
export const CONTACT_SALES = "Contact Sales";
export const LEARN_MORE = "Learn More";
export const MANAGE_BILLING = "Manage Billing";
export const SUPPORTED_BROWSERS = ["Chrome", "Edge", "Firefox", "Safari"];
export const UPGRADE_PLAN = "Upgrade Plan";
export const UPDATE_PLAN = "Update Plan";
export const VIEW_WORKSPACES = "View Workspaces";
export const WORKSPACE_TAB_DESTINATION = "/datasets";
const salesEmailAddress = getSalesEmailAddress();
const NotificationBarConstants = {
  overageMessagesAdmin: {
    userSeats: (
      <>
        You are currently over your user seat limit. Upgrade your plan or remove
        users to resume full Connect Cloud functionality.
      </>
    ),
    connections: (
      <>
        You are currently over your connection limit. Upgrade your plan or
        remove connections to resume full Connect Cloud functionality.
      </>
    ),
    userSeatsAndConnections: (
      <>
        You are currently over your user seat and connection limits. Upgrade
        your plan or remove users and connections to resume full Connect Cloud
        functionality.
      </>
    ),
  },

  overageMessagesQuery: {
    userSeats: (
      <>
        You are currently over your user seat limit. Please contact your system
        administrator for help.
      </>
    ),
    connections: (
      <>
        You are currently over your connection limit. Please contact your system
        administrator for help.
      </>
    ),
    userSeatsAndConnections: (
      <>
        You are currently over your user seat and connection limits. Please
        contact your system administrator for help.
      </>
    ),
  },

  trialRowOverageMessages: {
    admin: (
      <>
        You have currently reached your rows limit for your Trial period. Please
        upgrade your plan today or contact&nbsp;
        <a href={`mailto:${salesEmailAddress}`}>{salesEmailAddress}</a>&nbsp;for
        further assistance.
      </>
    ),
    query: (
      <>
        You have currently reached your rows limit for your Trial period. Please
        contact your system administrator for further assistance.
      </>
    ),
  },

  unsupportedBrowser: {
    message: (
      <>
        You are currently using an unsupported browser which may affect the
        performance of CData Connect Cloud.
      </>
    ),
  },

  billingIssueMessages: {
    unpaidAdmin: (
      <>
        Your account is currently past due on payment. To avoid interruptions,
        please update your billing details.
      </>
    ),
    unpaidQuery: (
      <>
        Your access to CData Connect Cloud will soon end. Please contact your
        system administrator for help.
      </>
    ),
    canceledAdmin: (
      <>
        Your account is currently in a canceled state. To continue using CData
        Connect Cloud, please reactivate your plan.
      </>
    ),
    canceledQuery: (
      <>
        Your access to CData Connect Cloud has ended. Please contact your system
        administrator for help.
      </>
    ),
    pausedAdmin: (
      <>
        Your account is currently paused. To resume your CData Connect Cloud
        subscription, please activate your plan.
      </>
    ),
    pausedQuery: (
      <>
        Your account is currently paused. To resume your CData Connect Cloud
        subscription, please contact your system administrator.
      </>
    ),
  },

  deprecatedPlan: {
    message: (
      <>
        Your current Connect Cloud subscription has been retired. You can
        continue using your legacy subscription plan, or you can upgrade to a
        newer subscription model for additional functionality.
      </>
    ),
  },

  deprecatedOData: {
    message: (
      <>
        OData will soon be replaced by Virtual Datasets. Start the transition
        now by adding your tables into a designated Workspace.
      </>
    ),
  },

  modifyingCacheConnection: {
    message: (
      <>
        Modifying your PostgresSQL connection will result in the clearing of
        cached data and trigger a restart for jobs that depend on this
        information.
      </>
    ),
  },
};

export default NotificationBarConstants;
