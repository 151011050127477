import { useFlagsmith, useFlags } from "flagsmith/react";

export function useFeatureFlags(): any {
  const flagsmith = useFlagsmith();

  // TODO: CLOUD-7495 - Merge in the GET flags functionality from useFlags to this hook and pass them through

  const setIdentity = (userId: string, email: string, accountId: string) => {
    if (!window.REACT_APP_CONFIG.REACT_APP_USER_FLAGS_ENABLED) return;

    if (userId && email) {
      flagsmith.identify(userId);
      flagsmith.setTrait("email", email);
      flagsmith.setTrait("accountId", accountId);
    }
  };

  const setTrait = (key: string, value: string) => {
    if (window.REACT_APP_CONFIG.REACT_APP_USER_FLAGS_ENABLED) return;

    if (key && value) {
      flagsmith.setTrait(key, value);
    }
  };

  const getFlags = (keys: string[]) => {
    return useFlags(keys);
  };

  return {
    setIdentity: setIdentity,
    setTrait: setTrait,
    getFlags: getFlags,
  };
}
