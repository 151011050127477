import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

type DropdownListInputProps = {
  label: string;
  name: string;
  list: JSX.Element[];
  defaultValue: string;
  onChange?: Function;
};

const DropdownListInput = (props: DropdownListInputProps) => {
  const [field] = useField(props.name);

  return (
    <Form.Group>
      <h5>{props.label}</h5>
      <Form.Control
        as="select"
        name={props.name}
        defaultValue={props.defaultValue}
        onBlur={field.onBlur}
        onChange={(e) => {
          field.onChange(e);
          if (props.onChange) props.onChange();
        }}
        className="form-select"
      >
        {props.list}
      </Form.Control>
    </Form.Group>
  );
};

export default DropdownListInput;
