import { AnyAction } from "redux";
import * as types from "../constants";

const initialState = {
  dragData: {
    type: "table",
    action: "drag",
    name: "",
    query: "",
    id: "",
    meta: {
      connectionName: "",
      tableName: "",
      schemaName: "",
    },
  },
};

export default function reducer(state: any = initialState, action: AnyAction) {
  switch (action.type) {
    case types.UPDATE_DRAG_DATA:
      return {
        ...state,
        dragData: action.payload,
      };
    default:
      return state;
  }
}
