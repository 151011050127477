//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Ops {
  None = 0,
  Select = 1,
  Insert = 2,
  Update = 4,
  Delete = 8,
  Execute = 16,
  All = 31
}
