// @ts-strict-ignore
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { Form } from "react-bootstrap";
import { ToastrSuccess } from "../../../services/toastrService";
import { RequestType } from "../../../components/withAPI";
import { IUser, UserRole } from "../../../models";
import Loader from "../../../components/Loader";
import { YupOptionalSchema } from "../../../utility/types/yupHelperTypes";
import { useAPI } from "../../../components/useAPI";
import { useSelector } from "react-redux";
import { CDataFormTextField } from "../../../components/form/CDataFormTextField";
import { CDataTextField } from "../../../components/form/CDataTextField";
import { CDataTypography } from "../../../components/CDataTypography";

function UserProfileCard() {
  const [userInfo, setUserInfo] = useState<IUser | null>(null);

  const api = useAPI();
  const user = useSelector((u) => u.user);
  const isOemSub = user.role === UserRole.ServiceUser;

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  async function getCurrentUserInfo() {
    const { status, payload } = await api.callAPI<IUser>(
      RequestType.Get,
      "/users/self",
      "Failed to get current user info due to the following error.",
      null,
      undefined,
      undefined,
      isOemSub,
    );
    if (status === 200) {
      setUserInfo(payload);
    }
  }

  async function handleValidSubmit(
    values: IUser,
    actions: FormikHelpers<IUser>,
  ) {
    const { status } = await api.callAPI(
      RequestType.Put,
      "/users/self",
      "Failed to update user due to the following error:",
      values,
      undefined,
      undefined,
      isOemSub,
    );
    if (status === 200) {
      ToastrSuccess(
        "User Updated Successfully!",
        `User ${userInfo.email} has been updated successfully.`,
      );
      actions.resetForm({ values });
    }
  }

  let roleName = "";
  switch (userInfo?.role) {
    case UserRole.Admin: {
      roleName = "Administrator";
      break;
    }
    case UserRole.Query: {
      roleName = "Query User";
      break;
    }
    case UserRole.OEMAdmin: {
      roleName = "OEM Administrator";
      break;
    }
  }

  if (!userInfo) {
    return <Loader />;
  }

  return (
    <Card>
      <CardBody>
        <Formik<IUser>
          initialValues={userInfo}
          validationSchema={Yup.object<YupOptionalSchema<IUser>>({
            firstName: Yup.string()
              .min(1, "First name must be between 1 and 100 characters")
              .max(100, "First name must be between 1 and 100 characters")
              .required("This is a required field"),
            lastName: Yup.string()
              .min(1, "Last name must be between 1 and 100 characters")
              .max(100, "Last name must be between 1 and 100 characters")
              .required("This is a required field"),
          })}
          onSubmit={(values, actions) => handleValidSubmit(values, actions)}
        >
          {({ isValid, dirty, handleSubmit }) => {
            return (
              <Form>
                <Row>
                  <Col>
                    <CDataTypography
                      variant="typography-variant-headline-4"
                      className="mb-4"
                    >
                      User Profile
                    </CDataTypography>
                  </Col>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() => handleSubmit()}
                      className="card-actions float-end"
                      disabled={!(isValid && dirty)}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        className="small-icon align-middle no-pointer-event"
                      />
                      Save Changes
                    </Button>
                  </Col>
                </Row>
                <CDataFormTextField
                  label="First Name"
                  name="firstName"
                  className="mb-3"
                  required
                />
                <CDataFormTextField
                  label="Last Name"
                  name="lastName"
                  className="mb-3"
                  required
                />
                <FormGroup>
                  <Input
                    type="hidden"
                    readOnly
                    disabled
                    defaultValue={userInfo.email}
                  ></Input>
                  <Input
                    type="hidden"
                    readOnly
                    disabled
                    defaultValue={userInfo.enabled ? "True" : "False"}
                  ></Input>
                </FormGroup>
                <CDataTextField
                  label="Role"
                  name="role"
                  disabled
                  value={roleName}
                />
              </Form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
}

export default UserProfileCard;
