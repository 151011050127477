//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AuditEventType {
  QueryLogDownload = 0,
  LogFileAccessGranted = 1,
  LogFileAccessRevoked = 2,
  AccountDeleteTriggered = 500,
  AccountDeleteProgress = 501,
  AccountDeleteFinished = 502,
  ConnectionCreated = 1000,
  ConnectionDeleted = 1001,
  ConnectionChangedName = 1002,
  ConnectionChangedConnStr = 1003,
  ConnectionChangedType = 1004,
  ConnectionChangedUserConnStr = 1005,
  InviteCreated = 1050,
  InviteResent = 1051,
  InviteDeleted = 1052,
  DerivedViewCreated = 1060,
  DerivedViewDeleted = 1061,
  DerivedViewChangedName = 1062,
  DerivedViewChangedQuery = 1063,
  CustomReportCreated = 1070,
  CustomReportDeleted = 1071,
  CustomReportChangedName = 1072,
  CustomReportChangedParams = 1073,
  WorkspaceCreated = 1080,
  WorkspaceDeleted = 1081,
  WorkspaceChangedName = 1082,
  WorkspaceChangedDescription = 1083,
  WorkspaceChangedIsOdataEnabled = 1084,
  WorkspaceChangedIsTDSEnabled = 1085,
  DataAssetCreated = 1090,
  DataAssetDeleted = 1091,
  DataAssetChangedAlias = 1092,
  DataAssetChangedDescription = 1093,
  DataAssetMoved = 1094,
  UserCreated = 1100,
  UserDeleted = 1101,
  UserEnabled = 1102,
  UserDisabled = 1103,
  UserPasswordRegen = 1104,
  UserRoleChange = 1105,
  UserPATCreated = 1106,
  UserPATDeleted = 1107,
  UserPATRegen = 1108,
  PermissionCreated = 1200,
  PermissionDeleted = 1201,
  PermissionChanged = 1202,
  FolderAssetCreated = 1220,
  FolderAssetDeleted = 1221,
  FolderAssetChangedName = 1222,
  FolderAssetChangedDescription = 1223,
  FolderAssetChangedWorkspace = 1224,
  CacheConnectionCreated = 1230,
  CacheConnectionUpdated = 1231
}
