//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FeatureId {
  QueryFederation = 0,
  DerivedViews = 1,
  UserDefinedCredentials = 2,
  PremiumDataSources = 3,
  SSO = 4,
  APIConnector = 5,
  Caching = 6,
  ScheduledQueries = 7,
  OnlySpreadsheetClients = 8,
  AIGenerator = 9
}
