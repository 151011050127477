import React from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  toggleSidebar,
  showSidebar,
  hideSidebar,
} from "../redux/actions/sidebarActions";
import cdataIcon from "../assets/img/icons/CloudLogo.svg";
import { useAuthentication } from "../hooks/useAuthentication";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import { UserRole } from "../models/Users/UserRole";
import OEMServiceUserHeader from "./OEMServiceUserHeader";
import { getAdminUserEmail } from "../services/userImpersonation";

interface INavbarComponentProps {
  toggleSidebar: Function;
  showSidebar: Function;
  hideSidebar: Function;
  sidebar: any;
}

const NavbarComponent: React.FC<INavbarComponentProps> = (props) => {
  const auth = useAuthentication();
  const user = useSelector((state) => state.user);
  const isOemSub = user?.role === UserRole.ServiceUser;
  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          if (props.sidebar.isOpen === props.sidebar.keepExpanded)
            props.toggleSidebar();
          else {
            props.sidebar.isOpen ? props.hideSidebar() : props.showSidebar();
          }
        }}
      >
        <i className="fa-regular fa-bars fa-2x align-self-center" />
      </span>
      <a className="logo-expanded" href="/">
        <img src={cdataIcon} className="cdata-logo" alt="" />
        <hr />
      </a>

      {isOemSub ? <OEMServiceUserHeader /> : null}

      <Collapse navbar>
        <Nav className="ms-auto" navbar>
          <a
            id="documentationLink"
            href="/docs/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-icon-container"
            aria-label="docs"
          >
            <i className="fa-solid fa-circle-question nav-icon"></i>
          </a>
          <UncontrolledTooltip
            placement="bottom"
            target="documentationLink"
            trigger="hover"
          >
            Documentation
          </UncontrolledTooltip>
          <NavLink
            id="settingsLink"
            to="/settings"
            className="nav-icon-container"
          >
            <i className="fa-solid fa-gear nav-icon"></i>
          </NavLink>
          <UncontrolledTooltip
            placement="bottom"
            target="settingsLink"
            trigger="hover"
          >
            Settings
          </UncontrolledTooltip>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              <span className="text-dark">
                {isOemSub ? getAdminUserEmail() : user?.email}
              </span>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem tag="a" onClick={() => auth.Logout()}>
                <i className="fa-regular fa-right-from-bracket fa-lg align-middle me-2"></i>
                Sign out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    hideSidebar: () => dispatch(hideSidebar()),
    showSidebar: () => dispatch(showSidebar()),
  };
};

// TODO: Figure out if there's a specific type this needs to be for redux
export default connect(
  (store: any) => ({
    sidebar: store.sidebar,
  }),
  mapDispatchToProps,
)(NavbarComponent);
