export function setImpersonatingUserId(userId: string) {
  window.sessionStorage.setItem("userIdToImpersonate", userId);
}

export function clearImpersonation() {
  window.sessionStorage.removeItem("userIdToImpersonate");
}

export function getImpersonatingUserId() {
  return window.sessionStorage.getItem("userIdToImpersonate") ?? undefined;
}

export function isImpersonating() {
  return window.sessionStorage.getItem("userIdToImpersonate") != null;
}

export function setAdminUserEmail(email: string) {
  window.sessionStorage.setItem("adminAccountEmail", email);
}

export function getAdminUserEmail() {
  return window.sessionStorage.getItem("adminAccountEmail") ?? undefined;
}
