import { BillingStatus } from "../../models/";

export interface IUsageAggregate {
  usage: number;
  usageLimit: number;
  periodStart?: string;
  periodEnd?: string;
}

export interface IUsage {
  aggregates: IUsageAggregate[];
  usageType: UsageType;
  billingStatus: BillingStatus;
  bottomDivider?: boolean;
}

export enum UsageType {
  "cached table",
  "connection",
  "credit",
  "data source",
  "row",
  "user seat",
}
