import React from "react";
import {
  NavigateFunction,
  Location,
  useLocation,
  useNavigate,
} from "react-router-dom";

export interface ComponentWithRouterProps {
  router: {
    location: Location;
    navigate: NavigateFunction;
  };
}

export const withRouter = (Component: any) => {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    return <Component {...props} router={{ location, navigate }} />;
  }
  return ComponentWithRouterProp;
};
