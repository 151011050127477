import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import classNames from "classnames";

import { NotificationType, INotificationBarProps } from "./INotificationBar";

export const NotificationBar = (props: INotificationBarProps) => {
  function getComponentName() {
    return "components-NotificationBar";
  }

  const bannerClasses = classNames({
    "notification-bar": true,
    "info-notification-bar": props.type === NotificationType.Info,
    "warning-notification-bar": props.type === NotificationType.Warning,
    "prerequisite-notification-bar":
      props.type === NotificationType.PrerequisiteWarning,
  });

  let icon;

  if (props.overrideIcon) {
    icon = props.overrideIcon;
  } else if (props.type === NotificationType.Info) {
    icon = faInfoCircle;
  } else {
    icon = faExclamationTriangle;
  }

  return (
    <div
      hidden={props.hidden}
      className={`p-0 w-100 ${getComponentName()}`}
      data-testid="notification-bar"
    >
      <Row className={bannerClasses}>
        <Col>
          <FontAwesomeIcon
            icon={icon}
            color="white"
            className="align-middle me-2 no-pointer-event notification-icon"
          />
        </Col>
        <Col>{props.message}</Col>
        {props.buttonText && (
          <Col>
            {props.to ? (
              <NavLink className="text-light" to={props.to}>
                <Button
                  transparent={"true"}
                  color="white"
                  size="sm"
                  className="manage-button"
                  data-testid="button-notification-link"
                >
                  {props.buttonText}
                </Button>
              </NavLink>
            ) : (
              <Button
                transparent={"true"}
                color="white"
                size="sm"
                className="manage-button"
                onClick={props.handleClick}
                data-testid="button-notification-clickable"
              >
                {props.buttonText}
              </Button>
            )}

            {props.onClose && (
              <Button
                transparent={"true"}
                color="white"
                size="sm"
                className="manage-button"
                onClick={props.onClose}
              >
                <i className="fa-regular fa-xmark me-1 close-button" />
              </Button>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};
