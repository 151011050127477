import React from "react";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import CDataButton, {
  ButtonType,
} from "../../../components/buttons/CDataButton";
import { VerifyEmailType } from "./AccountTabModals";
import { Input } from "reactstrap";

interface IVerifyEmailModalProps {
  open: boolean;
  close: () => void;
  email: string;
  emailType: VerifyEmailType;
  onSave: () => void;
}

export function VerifyEmailModal(props: IVerifyEmailModalProps) {
  const { open, close, email, emailType, onSave } = props;

  const warningText =
    emailType === VerifyEmailType.Login
      ? "You are attempting to change the email address associated with your login. In order to save this change, we need to verify that you have access to the following email address:"
      : "You are attempting to change the primary contact's email. In order to save this change, we need to verify that you have access to the following email address:";

  return (
    <CDataModalV2
      title="Verify Email"
      displayed={open}
      close={close}
      spacedFooter={false}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={() => {
            onSave();
          }}
        >
          Send Verification
        </CDataButton>
      }
      secondaryButton={
        <CDataButton
          buttonType={ButtonType.Secondary}
          onClick={() => {
            close();
          }}
        >
          Cancel
        </CDataButton>
      }
    >
      <div>
        <p>{warningText}</p>
        <h5>Email</h5>
        <Input disabled type="email" value={email} className="form-control" />
      </div>
    </CDataModalV2>
  );
}
