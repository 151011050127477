import React, { useState } from "react";
import classnames from "classnames";

import {
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";

export interface IPaginationButtonsProps {
  table: any;
  paginationOptions: number[];
}

function TanstackPaginationButtons({
  table,
  paginationOptions,
}: IPaginationButtonsProps) {
  const [isPaginationDropdownOpen, setIsPaginationDropdownOpen] =
    useState(false);

  const pageCount = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex;
  let startIndex = currentPage > 2 ? currentPage - 2 : 0;

  // Override start index if we are near the end of a long list of pagination results
  if (currentPage > 5) {
    if (pageCount - currentPage === 2) {
      startIndex = startIndex - 1;
    } else if (pageCount - currentPage === 1) {
      startIndex = startIndex - 2;
    }
  }

  const buttons = [];

  if (table.getRowModel().rows.length === 0) {
    // If data is empty, push a dummy, disabled, selected 1 button
    buttons.push(
      <li key="empty" className={"page-item active disabled"}>
        <a className="page-link disabled" onClick={undefined}>
          1
        </a>
      </li>,
    );
  } else {
    for (let i = startIndex; i < startIndex + 5 && i < pageCount; i++) {
      buttons.push(
        <li
          key={i.toString()}
          className={classnames("page-item", { active: currentPage === i })}
        >
          <a className="page-link" onClick={() => table.setPageIndex(i)}>
            {i + 1}
          </a>
        </li>,
      );
    }
  }

  return (
    <Row className="mt-2">
      <Col>
        <b className="me-2">Rows per page:</b>
        <ButtonDropdown
          isOpen={isPaginationDropdownOpen}
          toggle={() => setIsPaginationDropdownOpen(!isPaginationDropdownOpen)}
        >
          <DropdownToggle className="dropdown-toggle" caret>
            {table.getState().pagination.pageSize}
          </DropdownToggle>
          <DropdownMenu>
            {paginationOptions.map((paginationSize: number) => (
              <DropdownItem
                key={paginationSize}
                onClick={() => table.setPageSize(paginationSize)}
              >
                {paginationSize}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </Col>
      <Col>
        <ul className="pagination justify-content-end mb-0">
          <li
            className={classnames("page-item", {
              disabled: !table.getCanPreviousPage(),
            })}
          >
            <a
              className="page-link"
              onClick={() =>
                table.getCanPreviousPage() ? table.previousPage() : null
              }
              aria-disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </a>
          </li>
          {buttons}
          <li
            className={classnames("page-item", {
              disabled: !table.getCanNextPage(),
            })}
          >
            <a
              className="page-link"
              onClick={() => (table.getCanNextPage() ? table.nextPage() : null)}
              aria-disabled={!table.getCanNextPage()}
            >
              {">"}
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default TanstackPaginationButtons;
