// @ts-strict-ignore
import * as React from "react";
import { useEffect, useState } from "react";
import { addUser } from "../../redux/actions/userActions";
import { connect } from "react-redux";
import { withRouter } from "../withRouter";
import { IODataMetadata, IUser, UserRole } from "../../models/";
import { useAuthentication } from "../../hooks/useAuthentication";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import {
  getImpersonatingUserId,
  isImpersonating,
} from "../../services/userImpersonation";
import { prependApiPrefix } from "../../api/prependApiPrefix";

// For routes that can only be accessed by authenticated users
const UserAuthenticationGuard = (props: any) => {
  const { children, router } = props;
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const auth = useAuthentication();
  const featureFlags = useFeatureFlags();

  useEffect(() => {
    if (userInfo === null) {
      const asyncFn = async () => {
        if (await setCurrentUserInfo()) {
          const user = await getCurrentUserInfo();
          setUserInfo(user);
        }
      };
      asyncFn();
    }
  }, [auth.GetAccessTokenSilently, auth.User]);

  function setStoreUserInfo(user: IUser | null) {
    if (user == null) {
      window.sessionStorage.removeItem("userInfo");
    } else {
      window.sessionStorage.setItem("userInfo", JSON.stringify(user));
    }
  }

  async function setCurrentUserInfo() {
    setStoreUserInfo(null);
    const accessToken = await auth.GetAccessTokenSilently();

    //For SSO Login
    const nameSplit = auth.Name!.split(" ");
    const user: IUser = {
      email: auth.Email,
      accountId: "",
      accountName: "",
      canResetPassword: false,
      emailVerifyCode: "",
      emailVerifyExpires: "",
      enabled: false,
      canBeImpersonated: false,
      userImpersonationId: "",
      firstName: "",
      id: "",
      inviteCode: "",
      inviteExpires: "",
      isInvite: false,
      lastName: "",
      pendingEmail: "",
      permissions: [],
      role: UserRole.Admin,
      workspacePermissions: [],
      canImpersonateAsSupport: false,
    };

    if (nameSplit.length > 1) {
      user.firstName = nameSplit[0];
      user.lastName = nameSplit[1];
    } else {
      user.firstName = nameSplit[0];
    }

    const data = {
      Email: auth.Email,
      Name: auth.Name,
      EmailVerified: auth.Email_verified,
      IsSSOLogin:
        auth.User![
          window.REACT_APP_CONFIG.REACT_APP_AUTH_CUSTOM_CLAIMS_NAMESPACE +
            "is_pass_login"
        ] !== true,
      IdToken: accessToken,
      AccessToken: accessToken,
      ExternalId:
        auth.User![
          window.REACT_APP_CONFIG.REACT_APP_AUTH_CUSTOM_CLAIMS_NAMESPACE +
            "external_id"
        ],
      User: user,
    };

    const requestOptions = {
      method: "Post",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        // The impersonation header is intentionally not sent in this request so the user is still logged in as themselves
      },
    };

    const signInInfo = data;
    let route = "";
    //SSO Login or Social Login starts here
    if (signInInfo.IsSSOLogin) {
      if (!signInInfo.EmailVerified) {
        /*Redirect the user, and mark the response as handled so that the OpenIdConnect middleware doesn't
                set the cookies that would cause them to remain authenticated.*/
        route = "/auth/verify-email";
        props.router.navigate(route);
        return;
      }
      const accountConnectResponse = await fetch(
        prependApiPrefix("/account/loginAccountSSO"),
        requestOptions,
      );
      const accountConnectResponseJson = await accountConnectResponse.json();
      const errorCode = accountConnectResponseJson?.error?.code;
      if (errorCode) {
        if (errorCode === "ACCOUNT_NOT_FOUND") {
          if (window.REACT_APP_CONFIG.REACT_APP_SIGNUP_URL) {
            window.location.href = window.REACT_APP_CONFIG.REACT_APP_SIGNUP_URL;
          } else {
            route = `/auth/sign-up-initial?email=${encodeURIComponent(signInInfo.Email ?? "")}&isSSO=true`;
          }
        } else if (errorCode === "USER_NOT_FOUND") {
          route = "/auth/user-sign-up?isUserFound=1";
        } else if (errorCode === "USER_EXISTS") {
          route = "/";
        } else {
          route = "/auth/500";
        }
        props.router.navigate(route);
        return false;
      }
      return true;
    }

    /*Check whether the user's email has been verified by Auth. If not, they need to do that before
        anything else, so redirect them to a page which tells them that.*/
    if (!signInInfo.EmailVerified) {
      /*Redirect the user, and mark the response as handled so that the OpenIdConnect middleware doesn't
            set the cookies that would cause them to remain authenticated.*/
      props.router.navigate("/auth/verify-email");
      return false;
    }

    if (
      !signInInfo.ExternalId ||
      signInInfo.ExternalId === "MISSING_EXTERNAL_ID"
    ) {
      props.router.navigate("/auth/500");
      return false;
    }

    const loginResponse = await fetch(
      prependApiPrefix("/account/loginAccount"),
      requestOptions,
    );
    const loginResponseJson = await loginResponse.json();
    const errorCode = loginResponseJson?.error?.code;
    if (errorCode) {
      if (errorCode === "ACCOUNT_NOT_FOUND" || errorCode === "USER_NOT_FOUND") {
        if (window.REACT_APP_CONFIG.REACT_APP_SIGNUP_URL) {
          window.location.href = window.REACT_APP_CONFIG.REACT_APP_SIGNUP_URL;
        } else {
          route = `/auth/sign-up-initial?email=${encodeURIComponent(signInInfo.Email ?? "")}`;
        }
      } else if (errorCode === "USER_INVITED") {
        route = "/auth/user-sign-up";
      } else {
        route = "/auth/500";
      }
      props.router.navigate(route);
      return false;
    }
    return true;
  }

  async function getCurrentUserInfo() {
    try {
      const accessToken = await auth.GetAccessTokenSilently();

      const requestOptions: RequestInit = {
        method: "Get",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      if (isImpersonating()) {
        (requestOptions.headers as Record<string, string | undefined>)[
          "X-CData-Impersonated-User"
        ] = getImpersonatingUserId();
      }

      const response = await fetch(
        prependApiPrefix("/users/self"),
        requestOptions,
      );
      if (response.status === 200) {
        isODataVisible(accessToken);
        const user: IUser = await response.json();
        props.addUser(user);
        setStoreUserInfo(user);
        featureFlags.setIdentity(user.id, user.email, user.accountId);
        return user as IUser;
      } else {
        if (response.status === 401) {
          router.navigate("account/logout");
        }
      }
      return null;
    } catch (exception) {
      return null;
    }
  }

  async function isODataVisible(accessToken: string) {
    try {
      const requestOptions: RequestInit = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      if (isImpersonating()) {
        (requestOptions.headers as Record<string, string | undefined>)[
          "X-CData-Impersonated-User"
        ] = getImpersonatingUserId();
      }

      const response = await fetch(
        prependApiPrefix("/odataStorage/readFromBlob"),
        requestOptions,
      );
      const oDataEntities = JSON.parse(await response.text()) as IODataMetadata;
      const oDataVisible =
        response.status === 200 &&
        (oDataEntities.dataServices?.schema?.entityContainer?.entitySets ?? [])
          .length > 0;
      window.sessionStorage.setItem("isODataVisible", String(oDataVisible));
    } catch (error) {
      window.sessionStorage.setItem("isODataVisible", "false");
    }
  }

  return userInfo !== null ? (
    <AuthorizeContext.Provider value={userInfo}>
      {children}
    </AuthorizeContext.Provider>
  ) : null;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUser: (user: IUser) => dispatch(addUser(user)),
  };
};

export default connect(
  (store: any) => ({
    reduxUser: store.user,
  }),
  mapDispatchToProps,
)(withAuthenticationRequired(withRouter(UserAuthenticationGuard)));

export const AuthorizeContext = React.createContext(
  getStoreUserInfo() as IUser, // default value
);

function getStoreUserInfo(): IUser | null {
  const user = window.sessionStorage.getItem("userInfo");
  if (user) {
    return JSON.parse(user) as IUser;
  }
  return null;
}
