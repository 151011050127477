import { useAPI } from "../components/useAPI";
import { RequestType } from "../components/withAPI";
import { useDispatch } from "react-redux";
import {
  addAccountInfo,
  addCacheConnection,
  addDriversList,
  addSubscription,
  addUsage,
} from "../redux/actions";
import { useFeatureFlags } from "./useFeatureFlags";
import { getCacheConnection } from "../pages/caching/components/CachingApiCalls";

export function useSessionState() {
  const api = useAPI();
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();

  const getCurrentSubscriptionInfo = async () => {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/billing/subscription",
      "Could not get current plan",
    );
    if (status === 200) {
      dispatch(addSubscription(payload));
    }
  };

  const getCurrentDriverList = async () => {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/drivers",
      "Failed to get driver list due to the following error:",
    );
    if (status === 200) {
      dispatch(addDriversList(payload));
    }
  };

  const getBillingUsage = async () => {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/billing/usage",
      "Failed to get billing usage due to the following error:",
    );
    if (status === 200) {
      dispatch(addUsage(payload));
    }
  };

  const getAccountInfo = async () => {
    const { status, payload, error } = await api.callAPI(
      RequestType.Get,
      "/account/getInfo",
      "Failed to get account info due to the following error:",
    );

    if (status === 200) {
      dispatch(addAccountInfo(payload));
      featureFlags.setTrait("company", payload.organization);
    } else {
      const isOemUser = window.location.pathname.startsWith("/oem/user/");
      // We cannot send OEM users to login or sign-up pages, they get short lived access tokens.
      if (!isOemUser) {
        if (status === 401) {
          window.location.href = encodeURI(
            window.location.origin + "/account/login",
          );
        } else {
          if (error && error.message) {
            const redirectUri = error.message.includes("signing up")
              ? "/auth/user-sign-up"
              : "/account/logout";
            window.location.href = encodeURI(
              window.location.origin + redirectUri,
            );
          }
        }
      }
    }
  };

  const getCacheConnectionInfo = async () => {
    const payload = await getCacheConnection(api.callAPI);

    if (payload) {
      dispatch(addCacheConnection(payload));
    }
  };

  const initializeSessionState = async () => {
    await Promise.all([
      getCurrentSubscriptionInfo(),
      getCurrentDriverList(),
      getBillingUsage(),
      getAccountInfo(),
      getCacheConnectionInfo(),
    ]);
  };

  const modalAndAPIs = {
    getCurrentSubscriptionInfo: getCurrentSubscriptionInfo,
    getCurrentDriverList: getCurrentDriverList,
    getBillingUsage: getBillingUsage,
    getAccountInfo: getAccountInfo,
    getCacheConnectionInfo: getCacheConnectionInfo,
    initializeSessionState: initializeSessionState,
  };

  return modalAndAPIs;
}
