import React from "react";
import { cssTransition, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { ToastrBody, ToastrCloseButton } from "../components/toast/Toast";
import "../components/toast/Toast.scss";

const toastrTransition = cssTransition({
  enter: "fade-in",
  exit: "fade-out",
});

export function ToastrSuccess(title: string, body: string) {
  toast.success(<ToastrBody title={title} body={body} />, {
    autoClose: 5000,
    closeOnClick: true,
    closeButton: ToastrCloseButton,
    draggable: false,
    icon: <FontAwesomeIcon icon={faCheck} size="2x" />,
    pauseOnHover: true,
    progress: undefined,
    theme: "colored",
    transition: toastrTransition,
  });
}

export function ToastrWarning(title: string, body: string) {
  toast.warning(<ToastrBody title={title} body={body} />, {
    autoClose: 5000,
    closeOnClick: true,
    closeButton: ToastrCloseButton,
    draggable: false,
    icon: <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />,
    pauseOnHover: true,
    progress: undefined,
    theme: "colored",
    transition: toastrTransition,
  });
}

export function ToastrError(title: string, body: any, details?: any) {
  const bodyIsValidType =
    typeof body === "string" || React.isValidElement(body);
  body = bodyIsValidType ? body : JSON.stringify(body);

  toast.dismiss();
  toast.error(<ToastrBody title={title} body={body} details={details} />, {
    autoClose: false,
    closeOnClick: false,
    closeButton: ToastrCloseButton,
    draggable: false,
    icon: <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />,
    pauseOnHover: true,
    progress: undefined,
    theme: "colored",
    transition: toastrTransition,
  });
}

export function ToastrClean() {
  toast.dismiss();
}
