//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum APIAuthType {
  None = 0,
  Basic = 1,
  Digest = 2,
  OAuth1 = 3,
  OAuth2 = 4,
  AWSSignature = 5,
  OAuthClient = 6
}
