import React, { useState } from "react";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import CDataButton, {
  ButtonType,
} from "../../../components/buttons/CDataButton";
import { useSelector } from "react-redux";
import { useAPI } from "../../../components/useAPI";
import { RequestType } from "../../../components/withAPI";
import { logoutUser } from "../../../routes/AuthorizeContext";
import { Input } from "reactstrap";

interface IDeleteAccountModalProps {
  open: boolean;
  close: () => void;
}

export function DeleteAccountModal(props: IDeleteAccountModalProps) {
  const { open, close } = props;

  const api = useAPI();

  const [verifyEmail, setVerifyEmail] = useState("");
  const user = useSelector((u) => u.user);

  // Slightly confusingly, this matches the user email, not the account email.
  const verificationMatches =
    verifyEmail.toLowerCase() === user?.email?.toLowerCase();

  async function onAccountDelete() {
    const { status } = await api.callAPI(
      RequestType.Delete,
      "/account",
      "Failed to delete account due to the following error:",
    );
    if (status === 200 || status === 204) {
      logoutUser();
    }
  }

  return (
    <CDataModalV2
      title="Delete Account"
      displayed={open}
      close={close}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Danger}
          onClick={(event) => {
            event.preventDefault();
            onAccountDelete();
          }}
          disabled={!verificationMatches}
        >
          Confirm
        </CDataButton>
      }
      secondaryButton={
        <CDataButton buttonType={ButtonType.Secondary} onClick={() => close()}>
          Close
        </CDataButton>
      }
    >
      <div>
        <p>
          Enter your email address below to delete this account. This action
          cannot be undone.
        </p>
        <Input
          type="email"
          name="emailValidationBox"
          onChange={(event) => setVerifyEmail(event.target.value)}
          className="form-control"
        />
      </div>
    </CDataModalV2>
  );
}
