import React, { Component } from "react";
import { utc } from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import Loader from "../../../components/Loader";
import {
  successMessage,
  openPersonalAccessTokenModal,
  openRegeneratePersonalAccessTokenModal,
  openDeletePersonalAccessTokenModal,
} from "./AccessTokenTabModals";
import { IModalFunctions } from "../../../layouts/Dashboard";
import { ToastrSuccess } from "../../../services/toastrService";
import { withAPI, IAPI, RequestType } from "../../../components/withAPI";
import { IPersonalAccessToken } from "../../../models";
import { ColumnDef } from "@tanstack/react-table";
import ListTable from "../../../components/tables/ListTable";
import { orderBy } from "lodash";
import classNames from "classnames";

interface IAccessTokenProps extends IModalFunctions, IAPI {
  toggleModal: any;
}

interface IAccessTokenState {
  token: string;
  personalAccessTokens: IPersonalAccessToken[];
  processingRequest: boolean;
  loading: boolean;
}

class AccessToken extends Component<IAccessTokenProps, IAccessTokenState> {
  columns: ColumnDef<IPersonalAccessToken>[];
  constructor(props: IAccessTokenProps) {
    super(props);
    this.createPersonalAccessToken = this.createPersonalAccessToken.bind(this);
    this.deletePersonalAccessToken = this.deletePersonalAccessToken.bind(this);
    this.getPersonalAccessTokens = this.getPersonalAccessTokens.bind(this);
    this.regeneratePersonalAccessToken =
      this.regeneratePersonalAccessToken.bind(this);
    this.setToken = this.setToken.bind(this);
    this.state = {
      token: "",
      personalAccessTokens: [],
      processingRequest: false,
      loading: true,
    };
    this.columns = [
      {
        accessorKey: "name",
        enableSorting: false,
        header: "Name",
        meta: {
          className: "name-col",
        },
        cell: ({ row }) => {
          return <>{row.original.name}</>;
        },
      },
      {
        accessorKey: "creationData",
        enableSorting: false,
        header: "Creation Date",
        cell: ({ row }) => {
          return (
            <>
              {utc(row.original.created).format("YYYY-MM-DD HH:mm:ss") + " UTC"}
            </>
          );
        },
      },
      {
        accessorKey: "token",
        enableSorting: false,
        header: "Token",
        meta: {
          className: "token-col",
        },
        cell: ({ row }) => {
          return (
            <div className="token-row">
              <button
                type="button"
                onClick={() =>
                  openRegeneratePersonalAccessTokenModal(
                    this.props.setModal,
                    row.original.id ?? "",
                    this.props.toggleModal,
                    this.setToken,
                    this.regeneratePersonalAccessToken,
                  )
                }
                className="table-button"
                aria-label="regenerate token"
                data-testid="button-regenerate-pat"
              >
                <i className="fa-regular fa-arrows-rotate align-middle"></i>
              </button>
              <button
                type="button"
                onClick={() =>
                  openDeletePersonalAccessTokenModal(
                    this.props.setModal,
                    this.deletePersonalAccessToken,
                    this.props.toggleModal,
                    this.setToken,
                    row.original.id ?? "",
                  )
                }
                className="table-button"
                aria-label="delete token"
                data-testid="button-delete-pat"
              >
                <i className="fa-regular fa-xmark fa-lg align-middle"></i>
              </button>
            </div>
          );
        },
      },
    ];
  }

  public getComponentName() {
    return "pages-settings-AccessTokenTab-AccessTokenTab";
  }

  async componentDidMount() {
    await this.getPersonalAccessTokens();
  }

  async getPersonalAccessTokens() {
    const { status, payload } = await this.props.callAPI(
      RequestType.Get,
      "/users/self/pats",
      "Failed to get personal access token list due to the following error:",
    );
    if (status === 200) {
      this.setState({
        personalAccessTokens: payload.personalAccessTokens,
        loading: false,
      });
    }
  }

  setToken(token: string) {
    this.setState({ token: token });
  }

  async createPersonalAccessToken(event: React.FormEvent) {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const values = Object.fromEntries(formData.entries());

    const { status, payload } = await this.props.callAPI(
      RequestType.Post,
      "/users/self/pats",
      "Failed to create personal access token due to the following error:",
      values,
    );
    if (status === 200) {
      const data = payload;
      let personalAccessTokens = [...this.state.personalAccessTokens];
      personalAccessTokens.push(data);

      personalAccessTokens = orderBy(personalAccessTokens, (p) => p.name);

      successMessage(
        data.tokenString,
        this.props.toggleModal,
        this.setToken,
        this.props.setModal,
        true,
      );
      this.setState({
        token: data.tokenString,
        personalAccessTokens: personalAccessTokens,
        processingRequest: false,
      });
    } else {
      this.setState({ processingRequest: false });
    }
  }

  async regeneratePersonalAccessToken() {
    const { status, payload } = await this.props.callAPI(
      RequestType.Post,
      `/users/self/pats/${this.state.token}`,
      "Failed to regenerate personal access token due to the following error:",
    );
    if (status === 200) {
      successMessage(
        payload.tokenString,
        this.props.toggleModal,
        this.setToken,
        this.props.setModal,
        false,
      );
    } else {
      this.setState({ processingRequest: false });
    }
  }

  async deletePersonalAccessToken() {
    this.props.toggleModal();

    const personalAccessToken = this.state.personalAccessTokens.find(
      (token) => {
        return token.id === this.state.token;
      },
    );

    const { status } = await this.props.callAPI(
      RequestType.Delete,
      `/users/self/pats/${this.state.token}`,
      "Failed to delete personal access token due to the following error:",
    );

    if (status === 200) {
      const personalAccessTokens = this.state.personalAccessTokens.filter(
        (token) => {
          return token.id !== this.state.token;
        },
      );
      ToastrSuccess(
        "Personal Access Token Deleted Successfully",
        `Your personal access token '${personalAccessToken?.name}' has been deleted successfully.`,
      );
      this.setState({
        personalAccessTokens: personalAccessTokens,
      });
    }
  }

  static renderAccountPage(instance: AccessToken) {
    const personalAccessTokens: IPersonalAccessToken[] =
      instance.state.personalAccessTokens;

    return (
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4 className="mb-4">Personal Access Tokens</h4>
            </Col>
            <Col>
              <Button
                type="button"
                color="primary"
                className="card-actions float-end"
                onClick={() =>
                  openPersonalAccessTokenModal(
                    instance.props.setModal,
                    instance.createPersonalAccessToken,
                    instance.props.toggleModal,
                  )
                }
                data-testid="button-open-pat-modal"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="small-icon align-middle no-pointer-event"
                />
                Create PAT
              </Button>
            </Col>
          </Row>
          <ListTable
            className={classNames("personal-access-token-table", {
              "empty-table": personalAccessTokens.length === 0,
            })}
            columns={instance.columns}
            data={personalAccessTokens}
            emptyTableMessage={
              "Click on Create PAT to generate personal access tokens for your current user"
            }
            enableFiltering={false}
            enablePagination={false}
            enableCheckboxes={false}
          />
        </CardBody>
      </Card>
    );
  }

  render() {
    const contents = this.state.loading ? (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    ) : (
      AccessToken.renderAccountPage(this)
    );

    return (
      <Container fluid className={`p-0 ${this.getComponentName()}`}>
        {contents}
      </Container>
    );
  }
}

export default withAPI(AccessToken);
