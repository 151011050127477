// @ts-strict-ignore
import React, { useEffect } from "react";
import { useAuthentication } from "./hooks/useAuthentication";

export const DataDogUserLogger = () => {
  const auth = useAuthentication();

  useEffect(() => {
    if (auth.Email) {
      const { DD_RUM } = window;
      DD_RUM?.onReady(async function () {
        const claims = await auth.GetIdTokenClaims();
        const id = claims != null ? findIDFromClaims(claims) : undefined;
        DD_RUM.setUser({
          email: auth.Email,
          id: id,
        });
      });
    }
  }, [auth.User, window.location.hostname]);

  return <></>;
};

const findIDFromClaims = (claims: { [key: string]: any }) => {
  const claimKey = Object.keys(claims).find((key) =>
    key.endsWith("/claims/external_id"),
  );
  return claimKey ? claims[claimKey] : null;
};
