import { trimEnd, trimStart } from "lodash";

export function prependApiPrefix(url: string): string {
  url = trimStart(url, "/");

  const prefix = trimEnd(
    window.REACT_APP_CONFIG.REACT_APP_API_PREFIX ?? "",
    "/",
  );

  return `${prefix}/${url}`;
}
