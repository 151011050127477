import { prependApiPrefix } from "./prependApiPrefix";
import { getImpersonatingUserId } from "../services/userImpersonation";
import { isEmpty } from "lodash";

type RequestType = "GET" | "POST" | "PUT" | "DELETE";

type IApiOptions<TParameters> = {
  method: RequestType;
  url: string;
  body?: TParameters;
  headers?: Record<string, string>;
  /**
   * If true, the impersonation header will not be sent and the API call
   * will always be for the actual user, not the user they are impersonating
   */
  disableImpersonation?: boolean;
  /** Can be used to cancel a fetch request programmatically */
  abortSignal?: AbortSignal;
};

export async function cdataFetch<TReturn, TParameters = any>(
  options: IApiOptions<TParameters>,
): Promise<TReturn> {
  const { method, url, body, abortSignal } = options;

  try {
    const headers = await getHeaders(options);

    const response = await fetch(prependApiPrefix(url), {
      method: method,
      headers: headers,
      redirect: "follow",
      body: body ? JSON.stringify(body) : undefined,
      signal: abortSignal,
    });

    if (!response.ok) {
      await handleResponseError(response);
    }

    return getResponseData(response);
  } catch (err) {
    console.error("Fetch error", method, url, err);

    throw err;
  }
}

type IDownloadOptions<TParameters> = IApiOptions<TParameters> & {
  fileName: string;
  extension: string;
};

/**
 * A fetch wrapper that expects a blob to be returned from the server.
 * If the blob is successfully downloaded, a hidden link is added to the HTML that downloads the file automatically.
 */
export async function cdataDownloadFetch<TParameters = any>(
  options: IDownloadOptions<TParameters>,
): Promise<Blob> {
  const { method, url, body, fileName, extension, abortSignal } = options;

  try {
    const headers = await getHeaders(options);

    const response = await fetch(prependApiPrefix(url), {
      method: method,
      headers: headers,
      redirect: "follow",
      body: body ? JSON.stringify(body) : undefined,
      signal: abortSignal,
    });

    if (!response.ok) {
      await handleResponseError(response);
    }

    const blob = await response.blob();

    const windowUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = windowUrl;
    a.setAttribute("download", fileName + extension);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(windowUrl);

    return blob;
  } catch (err) {
    console.error("Fetch error", method, url, err);

    throw err;
  }
}

async function getHeaders(
  options: IApiOptions<unknown>,
): Promise<Record<string, string>> {
  const isOemUser = window.location.pathname.startsWith("/oem/user/");

  const { headers, disableImpersonation = false } = options;

  let accessToken: string | null = "";
  // OEM users have a JWT in session storage that authenticates them for a single page.
  if (isOemUser) {
    accessToken = sessionStorage.getItem("oemJwt");
  } else {
    accessToken = await window.GetAccessTokenSilently();
  }

  const defaultHeaders: Record<string, string> = {
    Authorization: `Bearer ${accessToken}`,
    "content-type": "application/json",
  };

  if (!disableImpersonation) {
    const impersonationId = getImpersonatingUserId();
    if (impersonationId != null && !isEmpty(impersonationId)) {
      defaultHeaders["X-CData-Impersonated-User"] = impersonationId;
    }
  }

  return Object.assign(defaultHeaders, headers ?? {});
}

function getResponseData(response: Response) {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    return response.json();
  } else {
    return response.text();
  }
}

async function handleResponseError(response: Response): Promise<void> {
  const data = await getResponseData(response);

  let errorDetails = "";

  if (typeof data === "string") {
    errorDetails = data;
  } else if ("error" in data) {
    // This is if the backend returns a ServiceError
    errorDetails = data.error?.message ?? "";
  } else {
    // We have no idea what the error is, just show whatever we received.
    errorDetails = JSON.stringify(data, null, 2);
  }

  // The 404 and 401 errors are special cased in <CDataQueryClientProvider />
  throw new HttpError(response.status, errorDetails);
}

export class HttpError extends Error {
  readonly statusCode: number;

  constructor(statusCode: number, message: string) {
    super(message);
    this.statusCode = statusCode;
  }
}
