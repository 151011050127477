// @ts-strict-ignore
import { IUser } from "../../models";
import { AnyAction } from "redux";
import * as types from "../constants";
import chameleon from "@chamaeleonidae/chmln";

const initialState: IUser = null!;

export default function reducer(
  state = initialState,
  action: AnyAction,
): IUser {
  switch (action.type) {
    case types.ADD_USER:
      registerUserWithChameleon(action.payload);
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}

function registerUserWithChameleon(user: IUser | null | undefined) {
  // If we haven't initialized chameleon (e.x. in local dev)
  // then just bail out.
  if (window.chmln == null) {
    return;
  }

  if (user != null) {
    try {
      chameleon.identify(user.id, {
        accountId: user.accountId,
      });
    } catch (err) {
      // Do not blow the app up if chameleon doesn't work.
      console.error("An error ocurred registering a user with chameleon");
      console.error(err);
    }
  }
}
