import classNames from "classnames";
import React, { ReactNode } from "react";
import {
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalProps,
} from "reactstrap";

// This is annoying, we normally would use an `Omit<ModalProps, ...>` here instead of Pick
// Unfortunately the reactstrap TypeScript types have this `[key: string]: any;`
// which means any prop is valid and we lose a lot of typechecking.
// Instead we use Pick to only pull out the props we may end up using.
type BaseModalProps = Pick<
  ModalProps,
  | "autoFocus"
  | "keyboard"
  | "backdrop"
  | "scrollable"
  | "onEnter"
  | "onExit"
  | "onOpened"
  | "onClosed"
  | "cssModule"
  | "wrapClassName"
  | "modalClassName"
  | "backdropClassName"
  | "contentClassName"
  | "zIndex"
  | "fade"
  | "backdropTransition"
  | "modalTransition"
  | "centered"
  | "fullscreen"
  | "external"
  | "labelledBy"
  | "unmountOnClose"
  | "returnFocusAfterClose"
  | "container"
  | "innerRef"
  | "trapFocus"
>;

export type ICDataModalV2Props = BaseModalProps & {
  /** Called when the modal is closed with either the header X button or pressing escape. Should typically toggle the `displayed` boolean to false. */
  close: () => void;
  /** True to show the modal, false to hide the modal. Note that the modal is not destroyed when this is false, just hidden. */
  displayed: boolean;
  /** The title set in the modal header, can be JSX. If set to null the header is not rendered. */
  title: ReactNode | undefined | null;
  children: ReactNode;
  /** Class added to the top-level `<Modal />` component. */
  className?: string;
  primaryButton?: JSX.Element;
  secondaryButton?: JSX.Element;
  /** @see The size prop from https://reactstrap.github.io/?path=/docs/components-modal--modal */
  modalSize?: "xl" | "lg" | "sm";
  /** False to hide the close button in the header */
  displayToggleCloseButton?: boolean;
  /** True to remove the border at the top of the footer. */
  removeBorders?: boolean;
  /**
   * Defaults to true, shows the secondary button on the left of the modal and the primary on the right.
   * If set to false, both buttons appear to the right of the modal.
   */
  spacedFooter?: boolean;
  /** True to remove the padding in the body of the modal so you can use your own padding. */
  removeYPaddingOnBody?: boolean;
  /** Props to pass to the `<ModalHeader />`  */
  modalHeaderProps?: ModalHeaderProps;
  /** Props to pass to the `<ModalBody />`  */
  modalBodyProps?: ModalBodyProps;
  /** Props to pass to the `<ModalFooter />`  */
  modalFooterProps?: ModalFooterProps;
};

export const CDataModalV2 = (props: ICDataModalV2Props) => {
  const {
    displayed,
    displayToggleCloseButton = true,
    title,
    close,
    primaryButton,
    secondaryButton,
    modalSize,
    className,
    removeBorders,
    spacedFooter = true,
    removeYPaddingOnBody,
    children,
    modalHeaderProps = {},
    modalBodyProps = {},
    modalFooterProps = {},
    ...modalProps
  } = props;

  const footerClasses = classNames(
    {
      "border-0": removeBorders,
      "spaced-footer": spacedFooter,
    },
    modalFooterProps?.className,
  );

  const bodyClasses = classNames(
    {
      "pt-0 pb-0": removeYPaddingOnBody,
    },
    modalBodyProps?.className,
  );

  return (
    <Modal
      centered
      {...modalProps}
      className={classNames(["components-CDataModalV2", className])}
      isOpen={displayed}
      toggle={displayToggleCloseButton ? close : undefined}
      backdrop="static"
      size={modalSize}
      data-testid="modal"
    >
      {title && (
        <ModalHeader
          data-testid="modal-header"
          toggle={displayToggleCloseButton ? close : undefined}
          {...modalHeaderProps}
          className={classNames([
            { "border-0": removeBorders },
            modalHeaderProps?.className,
          ])}
        >
          {title}
        </ModalHeader>
      )}

      <ModalBody
        data-testid="modal-body"
        {...modalBodyProps}
        className={classNames([bodyClasses, modalBodyProps?.className])}
      >
        {children}
      </ModalBody>

      <ModalFooter
        data-testid="modal-footer"
        hidden={!primaryButton && !secondaryButton}
        {...modalFooterProps}
        className={classNames([footerClasses, modalFooterProps?.className])}
      >
        {/* 
          Render an empty element if we aren't passed a secondary button but are passed a primary button.
          This makes it so the primary button shows up on the right.
        */}
        {secondaryButton || <span />}
        {primaryButton}
      </ModalFooter>
    </Modal>
  );
};
