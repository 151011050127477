import { RequestType } from "../../../components/withAPI";
import { IBatchCreateRequest, IBatchDeleteRequest } from "../../../models";
import { ICacheJob } from "../../../models/Cache/ICacheJob";
import { ICacheJobListItem } from "../../../models/Cache/ICacheJobListItem";
import { ICreateCacheJobRequest } from "../../../models/Cache/ICreateCacheJobRequest";

// Cache Jobs
export async function createCacheJob(
  callAPI: Function,
  job: ICreateCacheJobRequest,
) {
  const { status } = await callAPI(
    RequestType.Post,
    "/cacheJobs",
    "Could not create the cache job",
    job,
  );

  return status === 200;
}

export function deleteCacheJob() {
  return;
}

export async function getAllCacheJobs(callAPI: Function) {
  const { payload, status } = await callAPI(
    RequestType.Get,
    "/cacheJobs",
    "Failed to retrieve cache jobs.",
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function getCacheJob(callAPI: Function, jobId: string) {
  const { payload, status } = await callAPI(
    RequestType.Get,
    `/cacheJobs/${jobId}`,
    "Failed to retrieve cache job details.",
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function getTimeCheckColumnOptions(
  callAPI: Function,
  driverName: string,
) {
  const { status, payload } = await callAPI(
    RequestType.Get,
    `/drivers/${driverName}/timeCheckColumns`,
    "",
  );
  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function getTableColumns(
  callAPI: Function,
  connectionName: string,
  schemaName: string,
  tableName: string,
) {
  const searchParams = new URLSearchParams({
    catalogName: connectionName,
    schemaName: schemaName,
    tableName: tableName,
  });
  const columnURL = `/columns?${searchParams}`;
  const { status, payload } = await callAPI(RequestType.Get, columnURL, "");
  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function refreshCacheJob(callAPI: Function, job: ICacheJob) {
  const { status } = await callAPI(
    RequestType.Put,
    `/cacheJobs/${job.id}/refresh`,
    "Failed to refresh cache for job.",
  );

  return status === 200;
}

export async function runCacheJob(callAPI: Function, job: ICacheJobListItem) {
  const { status } = await callAPI(
    RequestType.Post,
    `/cacheJobs/${job.id}/run`,
    "Failed to manually run cache job.",
  );

  return status === 200;
}

export async function batchRunCacheJobs(
  callAPI: Function,
  requestModel: IBatchCreateRequest<string>,
) {
  const { status } = await callAPI(
    RequestType.Post,
    "/cacheJobs/run",
    "Failed to manually run cache job.",
    requestModel,
  );

  return status === 200;
}

export async function stopCacheJob(callAPI: Function, cacheJobId: string) {
  const { status } = await callAPI(
    RequestType.Put,
    `/cacheJobs/${cacheJobId}/stop`,
    "Failed to stop cache job.",
  );

  return status === 200;
}

export async function deleteCacheJobs(
  callAPI: Function,
  requestModel: IBatchDeleteRequest,
) {
  const { status } = await callAPI(
    RequestType.Delete,
    "/cacheJobs",
    "Failed to delete cache jobs.",
    requestModel,
  );

  return status === 200;
}

export async function toggleCacheJob(callAPI: Function, jobId: string) {
  const { status } = await callAPI(
    RequestType.Put,
    `/cacheJobs/${jobId}/toggle`,
    "Failed to toggle cache job.",
  );

  return status === 200;
}

export async function updateCacheJobs(
  callAPI: Function,
  requestModel: ICreateCacheJobRequest,
) {
  const { status } = await callAPI(
    RequestType.Put,
    "/cacheJobs/jobs",
    "Could not update the cache job",
    requestModel,
  );

  return status === 200;
}

// Error logs
export async function downloadCacheErrorLog(
  downloadAPI: Function,
  queryId: any,
) {
  await downloadAPI(
    `/account/queries/${queryId}/queryLogs`,
    "Cannot download logs due to the following error:",
    queryId,
  );
}

export async function getCacheErrorLogs(callAPI: Function, queryId: string) {
  const data: any = {};
  data["failed"] = true;
  data["QueryId"] = [queryId];

  const { status, payload } = await callAPI(
    RequestType.Post,
    "/log/query/list",
    "Failed to get list error logs due to the following error:",
    data,
  );
  if (status === 200) {
    return payload;
  }
}

// Cache Connections
export async function getCacheConnection(callAPI: Function) {
  const { payload, status } = await callAPI(
    RequestType.Get,
    "/cacheJobs/database",
    "Failed to retrieve caching connection information.",
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}
