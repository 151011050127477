//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum APITableRequestMethod {
  GET = 0,
  POST = 1,
  PUT = 2,
  DELETE = 3
}
