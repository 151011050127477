//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DataType {
  BINARY = 1,
  Min = 1,
  VARBINARY = 2,
  LONGVARBINARY = 3,
  BLOB = 4,
  VARCHAR = 5,
  TINYINT = 6,
  SMALLINT = 7,
  INTEGER = 8,
  BIGINT = 9,
  FLOAT = 10,
  DOUBLE = 11,
  DECIMAL = 12,
  NUMERIC = 13,
  BOOLEAN = 14,
  DATE = 15,
  TIME = 16,
  TIMESTAMP = 17,
  UUID = 18,
  Max = 18
}
