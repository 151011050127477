import { SettingTabs } from "../pages/settings/Settings";

export function getSettingsPageSubscriptionTab() {
  return window.REACT_APP_CONFIG?.REACT_APP_SUBSCRIPTION_PROVIDER === "Stripe"
    ? SettingTabs.Billing
    : SettingTabs.License;
}

export function isBillingTabVisible() {
  switch (window.REACT_APP_CONFIG.REACT_APP_SUBSCRIPTION_PROVIDER) {
    case "Stripe":
      return true;
    case "Redistribution":
      return false;
    default:
      return false;
  }
}

export function isLicenseTabVisible() {
  switch (window.REACT_APP_CONFIG.REACT_APP_SUBSCRIPTION_PROVIDER) {
    case "Stripe":
      return false;
    case "Redistribution":
      return false;
    default:
      return true;
  }
}

export function isManageSubscriptionVisible() {
  return (
    window.REACT_APP_CONFIG.REACT_APP_SUBSCRIPTION_PROVIDER !== "Redistribution"
  );
}

export function ManageSubscriptionText() {
  return window.REACT_APP_CONFIG.REACT_APP_SUBSCRIPTION_PROVIDER === "Stripe"
    ? "Manage Subscription"
    : "Manage License";
}
