//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export abstract class ODataEdmType
{
  public static BIGINT: string = `Edm.Int64`;
  public static BINARY: string = `Edm.Binary`;
  public static BIT: string = `Edm.Boolean`;
  public static BOOL: string = `Edm.Boolean`;
  public static BOOLEAN: string = `Edm.Boolean`;
  public static BLOB: string = `Edm.String`;
  public static CHAR: string = `Edm.String`;
  public static DATE: string = `Edm.Date`;
  public static DATETIME: string = `Edm.DateTimeOffset`;
  public static DECIMAL: string = `Edm.Decimal`;
  public static DOUBLE: string = `Edm.Double`;
  public static DURATION: string = `Edm.Duration`;
  public static FLOAT: string = `Edm.Double`;
  public static GEOGRAPHYPOINT: string = `Edm.GeographyPoint`;
  public static GUID: string = `Edm.Guid`;
  public static INT: string = `Edm.Int32`;
  public static INTEGER: string = `Edm.Int32`;
  public static LONGVARBINARY: string = `Edm.Binary`;
  public static LONGVARCHAR: string = `Edm.String`;
  public static NUMERIC: string = `Edm.Int32`;
  public static REAL: string = `Edm.Single`;
  public static SINGLE: string = `Edm.Single`;
  public static SMALLINT: string = `Edm.Int16`;
  public static STRING: string = `Edm.String`;
  public static TIME: string = `Edm.TimeOfDay`;
  public static TIMESTAMP: string = `Edm.DateTimeOffset`;
  public static TINYINT: string = `Edm.Byte`;
  public static UUID: string = `Edm.Guid`;
  public static VARBINARY: string = `Edm.Binary`;
  public static VARCHAR: string = `Edm.String`;
}
