//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum APIPaginationType {
  None = 0,
  Offset = 1,
  PageNumber = 2,
  PageToken = 3,
  URL = 4
}
