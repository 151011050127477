import React from "react";
import { lazyRetry } from "../utility/lazyRetry";

// Auth
const AuthRoutes = lazyRetry(() => import("../pages/auth/AuthRoutes"));
import Page404 from "../pages/auth/Page404";
import UserAuthenticationGuard from "../components/guards/UserAuthenticationGuard";
import AdminGuard from "../components/guards/AdminGuard";
import QueryUserGuard from "../components/guards/QueryUserGuard";
import OEMAdminGuard from "../components/guards/OEMAdminGuard";
const Logout = lazyRetry(() => import("../pages/auth/Logout"));

// Sign Up
const SignUpLayout = lazyRetry(() => import("../layouts/SignUpLayout"));
const SignUpInitial = lazyRetry(() => import("../pages/auth/SignUpInitial"));

// First Time Setup
const InitialSetupWizard = lazyRetry(
  () => import("../pages/initialSetup/InitialSetupWizard"),
);

// Settings
const Settings = lazyRetry(() => import("../pages/settings/Settings"));

// Users
const UserRoutes = lazyRetry(() => import("../pages/users/UserRoutes"));

// Status
const Status = lazyRetry(() => import("../pages/status/Status"));

// Connections
const ConnectionRoutes = lazyRetry(
  () => import("../pages/connections/ConnectionRoutes"),
);
const OAuthCallback = lazyRetry(
  () => import("../pages/connections/OAuthCallback"),
);
const ApiConnector = lazyRetry(
  () => import("../pages/apiConnector/ApiConnector"),
);

// OData
const ODataRoutes = lazyRetry(() => import("../pages/odata/ODataRoutes"));

// Client Connectivity
const Clients = lazyRetry(() => import("../pages/clientConnectivity/Clients"));

// Datasets
const DatasetsRoutes = lazyRetry(
  () => import("../pages/datasets/DatasetsRoutes"),
);

// Caching
const CachingRoutes = lazyRetry(() => import("../pages/caching/CachingRoutes"));

// Data Explorer
const DataExplorer = lazyRetry(
  () => import("../pages/dataExplorer/DataExplorer"),
);

// Logs
const Logs = lazyRetry(() => import("../pages/logs/Logs"));

// impersonation
const Impersonation = lazyRetry(
  () => import("../pages/impersonation/Impersonation"),
);

// OEM
const OEMAdminRoutes = lazyRetry(() => import("../pages/oem/OEMAdminRoutes"));
const OEMUserRoutes = lazyRetry(() => import("../pages/oem/OEMUserRoutes"));
const OEMLandingPage = lazyRetry(
  () => import("../pages/oem/oemlandingpage/OEMLandingPage"),
);

import Dashboard from "../layouts/Dashboard"; // This is intentionally not lazy imported since it's displayed for almost all routes.

export function getRouterList(
  showErrorModal: Function,
  showTimeoutModal: Function,
  showUnsupportedBrowser: Function,
  setModal: Function,
  toggleModal: () => void,
) {
  return [
    {
      path: "initial-setup",
      element: (
        <UserAuthenticationGuard>
          <InitialSetupWizard />
        </UserAuthenticationGuard>
      ),
    },
    {
      path: "account/logout",
      element: <Logout />,
    },
    {
      path: "/connections/oauth-callback/:callbackId",
      element: <OAuthCallback />,
    },
    {
      path: "auth/sign-up",
      element: (
        <SignUpLayout>
          <SignUpInitial />
        </SignUpLayout>
      ),
    },
    {
      path: "auth/sign-up-initial",
      element: (
        <SignUpLayout>
          <SignUpInitial />
        </SignUpLayout>
      ),
    },
    {
      path: "account/login",
      element: (
        <UserAuthenticationGuard>
          <InitialSetupWizard />
        </UserAuthenticationGuard>
      ),
    },
    {
      path: "auth/*",
      element: <AuthRoutes />,
    },
    {
      path: "oem/user/*",
      element: <OEMUserRoutes />,
    },
    {
      path: "oem/*",
      element: <OEMAdminRoutes />,
    },
    {
      path: "/",
      element: (
        <UserAuthenticationGuard>
          <Dashboard />
        </UserAuthenticationGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <>
              <AdminGuard>
                <Status />
              </AdminGuard>
              <QueryUserGuard>
                <Clients
                  showErrorModal={showErrorModal}
                  showTimeoutModal={showTimeoutModal}
                  setModal={setModal}
                  toggleModal={toggleModal}
                />
              </QueryUserGuard>
              <OEMAdminGuard>
                <OEMLandingPage />
              </OEMAdminGuard>
            </>
          ),
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "connections/*",
          element: <ConnectionRoutes />,
        },
        {
          path: "apiConnector",
          element: <ApiConnector />,
        },
        {
          path: "caching/*",
          element: <CachingRoutes />,
        },
        {
          path: "clients",
          element: (
            <Clients
              showErrorModal={showErrorModal}
              showTimeoutModal={showTimeoutModal}
              showUnsupportedBrowser={showUnsupportedBrowser}
              setModal={setModal}
              toggleModal={toggleModal}
            />
          ),
        },
        {
          path: "datasets/*",
          element: <DatasetsRoutes />,
        },
        {
          path: "data-explorer",
          element: <DataExplorer />,
        },
        {
          path: "user/*",
          element: <UserRoutes />,
        },
        {
          path: "odata/*",
          element: <ODataRoutes />,
        },
        {
          path: "logs",
          element: <Logs />,
        },
        {
          path: "impersonation",
          element: <Impersonation />,
        },
      ],
    },
    {
      path: "*",
      element: <Page404 showBranding={true} />,
    },
  ];
}
